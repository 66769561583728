import React, { useState, useEffect, Fragment } from 'react'
import {
    Box,
    Button,
    Stack,
    Flex,
    H3,
    H4,
    PseudoElement,
    Img,
    PageTitle,
    PageContent,
    Span,
    ButtonStack,
    FilterButton,
    Badge,
    Link,
    Icon,
    Avatar,
    useI18N,
    Tooltip,
    // initFilteredDataTable,
    // FilteredDataTable
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'
import { convertStatusState } from 'helpers/status-helper'
import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'
import { formatDateTime } from 'helpers/date-helper'

const WorkList = (props) => {
    const { formatCurrency } = useI18N()
    const [pageData, setPageData] = useState()
    const [refresh, setRefresh] = useState(false)
    const { getRequest, postRequest } = useRequest()
    const [dataError, setDataError] = useState(false)
    const [enterprises, setEnterprises] = useState([])
    const [filterData, setFilterData] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [activeFilters, setActiveFilters] = useState()
    const [tableLoaded, setTableLoaded] = useState(false)
    const [workDownload, setWorkDownload] = useState(false)
    const [selectedEnterprise, setSelectedEnterprise] = useState(null)

    const transformFilters = (filters) => {
        return filters
    }

    const transformQuickFilters = (quickFilters) => {
        return quickFilters
    }

    useEffect(() => {
        if (!!selectedEnterprise && !tableLoaded) {
            initFilteredDataTable({
                filterPath: 'work/filters',
                dataPath: `work/${selectedEnterprise.id}/search`,
                location: props.location,
                setFilterData: setFilterData,
                setDataError: setDataError,
                queryParams: [
                    { "enterpriseId": selectedEnterprise.id }
                ]
            })
                // .then(showFilter => {
                //     setShowFilter(showFilter)
                // })
                .finally(() => setTableLoaded(true))

        }
    }, [selectedEnterprise && tableLoaded])

    const columns = [
        { "header": "Reference", "accessor": "reference", "sortId": "1", "align": "left" },
        { "header": "Supplier", "accessor": "supplier", "sortId": "4", "align": "left" },
        { "header": "Title", "accessor": "title", "sortId": "3", maxWidth: "20rem", "align": "left" },
        { "header": "Owner", "accessor": "owner", "sortId": "5", "overflow": "visible", "align": "center" },
        { "header": "Amount", "accessor": "amount", "sortId": "9", "align": "center" },
        { "header": "Last Updated", "accessor": "updatedOn", "sortId": "6", "align": "center" },
        { "header": "Status", "accessor": "status", "sortId": "7", "align": "right" },
    ]

    const transform = (data) => {
        return {
            key: data.id,
            id: data.id,
            reference: <Link action={`work/${data.enterprise.id}/${data.id}`} openInNewTab={true}>{data.reference}</Link>,
            title: data.title,
            supplier: data.supplier.name,
            owner: (
                <Flex justifyContent="center">
                    <Tooltip variant="small" text={data.procurementOwner.name}>
                        <Avatar name={data.procurementOwner.name} size={28} />
                    </Tooltip>
                </Flex>
            ),
            updatedOn: !!data.updatedOn ? formatDateTime(data.updatedOn) : "-",
            status: <Badge status={convertStatusState(data.status.state)}>{data.status.title}</Badge>,
            amount: formatCurrency(data.currencyCode, data.amount),
        }
    }

    const getFilterParams = () => {
        return {
            filters: activeFilters.map(af => af.filter),
            page: pageData.page,
            pageSize: pageData.pageSize
        }
    }

    const downloadFile = (path, fileName) => {
        const filters = getFilterParams()
        return postRequest(`${path}`, filters)
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob)
                var a = document.createElement('a')
                a.href = url
                a.download = fileName
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
    }

    const downloadWorkCsv = () => {
        setWorkDownload(true)
        downloadFile(`work/export/${selectedEnterprise.id}`, `${selectedEnterprise.name} - Work-${new Date()}.csv`)
            .finally(() => setWorkDownload(false))
    }

    const groupBy = (n, data) => {
        let result = [];
        for (let i = 0; i < data.length; i += n)
            result.push(data.slice(i, i + n))

        return result
    };

    const backPressed = () => {
        setSelectedEnterprise(null)
        setShowFilter(false)
        setTableLoaded(false);
        props.location.state.selectedOrg = null
        //        window.history.replaceState({}, document.title)    
    }

    useEffect(() => {
        getRequest("enterprise", { active: true })
            .then((res) => setEnterprises(res || []))
            .catch(() => setDataError(true))
    }, [])

    return (
        <LoadingScreen
            hasData={enterprises.length > 0}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title="Work" description={(!!selectedEnterprise && !!tableLoaded) ? `Work for ${selectedEnterprise.name}` : "Please select an enterprise to view Work"} slim>
                        {
                            (!!selectedEnterprise && !!tableLoaded) &&
                            <ButtonStack row>
                                <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)} activeFilters={activeFilters} />
                                <Button onClick={() => downloadWorkCsv()} variant="secondary" aria-label="Download" isLoading={workDownload} showChevron>{workDownload ? "Downloading..." : "Download"}</Button>
                            </ButtonStack>
                        }
                    </PageTitle>
                    <PageContent slim>
                        <Stack spacing={8}>
                            {
                                (!!selectedEnterprise && !!tableLoaded) &&
                                <Stack spacing={8} row alignItems="center">
                                    <Stack row spacing={8} alignItems="center" cursor="pointer" my="auto" color="blue.600" onClick={() => backPressed()}>
                                        <Icon name="arrow-left" />
                                        <H3 color="body.link">All clients</H3>
                                        <Span>/</Span>
                                    </Stack>
                                    <H3 mt="auto" mb="auto" mr="auto">{selectedEnterprise.name}</H3>
                                    <Box mt="auto" mb="auto" padding={8} >
                                        <Img src={selectedEnterprise.logo} alt="Logo" height="2rem" />
                                    </Box>
                                </Stack>
                            }
                            {
                                (!!selectedEnterprise && !!tableLoaded) ?
                                    <FilteredDataTable
                                        initData={filterData}
                                        showFilter={showFilter}
                                        setShowFilter={setShowFilter}
                                        setDataError={setDataError}
                                        transform={transform}
                                        columns={columns}
                                        transformFilters={transformFilters}
                                        transformQuickFilters={transformQuickFilters}
                                        setParentActiveFilters={setActiveFilters}
                                        setParentPageData={setPageData}
                                        sortable={true}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        onLoadingChanged={(loading) => console.log(!loading)}
                                        queryParams={[
                                            { "enterpriseId": selectedEnterprise.id },
                                        ]} />
                                    : <Stack>
                                        {
                                            groupBy(3, enterprises).map((row, i) =>
                                                <Stack key={`row-${i}`} row>
                                                    {
                                                        row.map((enterprise, i) => (<Stack flex="1" maxWidth="33%" key={`item-${i}`} responsive>
                                                            <Box>
                                                                <Flex>
                                                                    <Flex alignItems="center" flexDirection="row" flexWrap="wrap">
                                                                        <H4 accessibilityHeading="h3">{enterprise.name}</H4>
                                                                    </Flex>
                                                                    <PseudoElement ml="auto">
                                                                        <Box padding={8}>
                                                                            <Img src={enterprise.logo} alt="Logo" height="2rem" />
                                                                        </Box>
                                                                    </PseudoElement>
                                                                </Flex>
                                                                <Button
                                                                    isLoading={!!selectedEnterprise && selectedEnterprise.id === enterprise.id}
                                                                    disabled={!!selectedEnterprise}
                                                                    mt={12}
                                                                    variant="secondary"
                                                                    onClick={() => setSelectedEnterprise(enterprise)}>View Work</Button>
                                                            </Box>
                                                        </Stack>))
                                                    }
                                                </Stack>)
                                        }
                                    </Stack>
                            }
                        </Stack>
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default WorkList