import React, { useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import {
    P,
    Box,
    Stack,
    HeaderSection,
    UploadedFile
} from 'paid-ui-lib'

const Attachments = ({ organisationId, enterpriseId, onRefresh, ...rest }) => {
    const { getRequest } = useRequest()
    const [attachments, setAttachments] = useState(null)

    useEffect(() => {
        getRequest(`organisations/${organisationId}/${enterpriseId}/attachments`)
            .then(res => {
                setAttachments(res)
            })
    }, [])

    return (
        <HeaderSection
            title="Attachments"
            {...rest}>
            <LoadingScreen
                hasData={attachments}
                hasError={false}
                render={() => (
                    <Box>
                        {
                            attachments.length === 0
                                ? <P>No Attachments Found</P>
                                : (
                                    <Stack>
                                        {attachments.map(ps => <UploadedFile key={ps.id} {...ps} />)}
                                    </Stack>
                                )
                        }
                    </Box>
                )} />
        </HeaderSection>
    )
}

export default Attachments