import React, { Fragment, useState, useEffect } from 'react'
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'
import {
    PageTitle,
    formatDateTime,
    PageContent,
    Link,
    Stack,
    Sub,
    H4,
    Box,
    Button,
    PseudoElement,
    Img
} from 'paid-ui-lib'

const ListEnterpriseView = (props) => {
    const [enterprises, setBuyers] = useState([])
    const [dataError, setDataError] = useState(false)
    const { getRequest } = useRequest()

    useEffect(() => {
        getRequest('enterprise?active=true')
            .then(setBuyers)
            .catch(() => setDataError(true))
    }, [])

    const enterpriseHeadings = [
        { "header": "Name", "accessor": "name" },
        { "header": "Supplier Count", "accessor": "supplierCount" },
        { "header": "Work Count", "accessor": "workCount" },
        { "header": "Created On", "accessor": "createdOn" },
        { "header": "Updated On", "accessor": "updatedOn" }
    ]

    const enterpriseTransform = (data) => {
        return {
            name: <Link action={`/enterprise/${data.id}`}>{data.name}</Link>,
            supplierCount: data.supplierCount,
            workCount: data.projectCount,
            createdOn: formatDateTime(data.createdOn),
            updatedOn: !!data.updatedOn ? formatDateTime(data.updatedOn) : "N/A",
        }
    }

    const groupBy = (n, data) => {
        let result = [];
        for (let i = 0; i < data.length; i += n)
            result.push(data.slice(i, i + n))

        return result
    };


    return (
        <LoadingScreen
            hasData={enterprises.length > 0}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle title="Enterprise" description="The enterprise base" slim />
                    <PageContent slim>
                        <Stack>
                            {
                                groupBy(3, enterprises).map((row, i) =>
                                    <Stack key={`row-${i}`} row>
                                        {
                                            row.map((enterprise, i) => (<Stack flex="1" maxWidth="33%" key={`item-${i}`} responsive>
                                                <Box>
                                                    <Stack>
                                                        <Stack alignItems="center" row>
                                                            <H4 flex="1" accessibilityHeading="h3">{enterprise.name}</H4>
                                                            <PseudoElement ml="auto">
                                                                <Box padding={8}>
                                                                    <Img src={enterprise.logo} alt="Logo" height="2rem" />
                                                                </Box>
                                                            </PseudoElement>
                                                        </Stack>
                                                        <Stack flex="1" spacing={8}>
                                                            <Stack row>
                                                                <Sub flex="1">Created On:</Sub>
                                                                <Sub>{formatDateTime(enterprise.createdOn)}</Sub>
                                                            </Stack>
                                                            <Stack row>
                                                                <Sub flex="1">Updated On:</Sub>
                                                                <Sub>{!!enterprise.updatedOn ? formatDateTime(enterprise.updatedOn) : "N/A"}</Sub>
                                                            </Stack>
                                                            <Stack row>
                                                                <Sub flex="1">Supplier Count:</Sub>
                                                                <Sub>{enterprise.supplierCount}</Sub>
                                                            </Stack>
                                                            <Stack row>
                                                                <Sub flex="1">Work Count:</Sub>
                                                                <Sub>{enterprise.projectCount}</Sub>
                                                            </Stack>
                                                        </Stack>
                                                        <Button variant="secondary" onClick={() => props.history.push(`/enterprise/${enterprise.id}`)}>View</Button>
                                                    </Stack>
                                                </Box>
                                            </Stack>))
                                        }
                                    </Stack>)
                            }
                        </Stack>
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default ListEnterpriseView