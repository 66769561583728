import React, { Fragment, useState, useEffect } from 'react'
import { useRequest } from 'components/use-request'
import {
    PageContent,
    PageTitle,
    Stack,
} from 'paid-ui-lib'

import LoadingScreen from 'components/LoadingScreen'
import BuyerDetailsTab from './components/BuyerDetailsTab'

const ViewBuyerView = (props) => {

    const { getRequest } = useRequest()
    const [enterprise, setEnterprise] = useState(null)
    const [dataError, setDataError] = useState(false)
    const enterpriseId = props.match.params.enterpriseId;

    const getEnterprise = async (id) => {
        const response = await getRequest(`enterprise/${id}`);
        !!response && setEnterprise(response);
        setDataError(!response)
    }
    useEffect(() => {
        !!enterpriseId && getEnterprise(enterpriseId)
    }, [enterpriseId])

    return (
        <LoadingScreen
            hasData={!!enterprise}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <PageTitle
                        title={enterprise.name}
                        description="View and manage this enterprise" />
                    <PageContent slim>
                        <Stack row responsive responsiveReverse>
                            <BuyerDetailsTab enterprise={enterprise} onRefresh={() => getEnterprise(enterpriseId)} />
                        </Stack>
                    </PageContent>
                </Fragment>
            )}
        />
    )
}

export default ViewBuyerView