import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import {
    A,
    Stack,
    Button,
    ButtonStack,
    HeaderSection,
    Box,
    Field,
    FooterMonthBarChart,
    useToast
} from 'paid-ui-lib'

import ConfirmModal from 'components/ConfirmModal'
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'
import cronstrue from 'cronstrue'

const BuyerDetailsTab = ({ enterprise, onRefresh }) => {
    const history = useHistory();
    const toast = useToast();
    const { getRequest, postRequest } = useRequest()
    const [actionFinanceOption, setActionFinanceOption] = useState(null)
    const [financeOptions, setFinanceOptions] = useState(null)
    const [express, setExpress] = useState(null)
    const [standard, setStandard] = useState(null)
    const [dataError, setDataError] = useState(false)

    useEffect(() => {
        getRequest(`payments/${enterprise.id}/average?express=true`)
            .then(res => {
                let values = []
                res.forEach(element => {
                    values.push({
                        "index": element.key,
                        "value": element.value
                    })
                });

                setExpress(values.reverse())
            })
            .catch(() => {
                setDataError(true)
            })

        getRequest(`payments/${enterprise.id}/average?express=false`)
            .then(res => {
                let values = []
                res.forEach(element => {
                    values.push({
                        "index": element.key,
                        "value": element.value
                    })
                });

                setStandard(values.reverse())
            })
            .catch(() => {
                setDataError(true)
            })
    }, [])

    const workTabClick = () => {
        history.push("/work")
    }

    const suppliersTabClick = () => {
        history.push({
            pathname: "/organisations",
            state: {
                filters: [
                    {
                        "id": "cb451f79-0f99-4446-a783-e433f700840e",
                        "filter": {
                            "id": "6",
                            "values": {
                                "0": "1",
                                "1": `${enterprise.id}`
                            }
                        },
                        "text": [
                            {
                                "value": "Enterprise",
                                "isSubject": true
                            },
                            {
                                "value": "Is",
                                "isSubject": false
                            },
                            {
                                "value": `${enterprise.name}`,
                                "isSubject": true
                            }
                        ]
                    }
                ]
            }
        })
    }

    const updateFinanceOptions = () => {
        if (!actionFinanceOption) return;
        const enabled = !actionFinanceOption.enabled;
        return postRequest(`finance/organisations/${enterprise.id}/finance-options/${actionFinanceOption.id}`, { enabled: enabled })
            .then(r => {
                !!onRefresh && onRefresh(r);
                toast({ slim: true, position: "top-right", title: `Finance ${enabled ? 'Enabled' : 'Disabled'}`, description: `Finance provider successfully updated`, status: "success", isClosable: true })
            })
            .catch(() => {
                setDataError(true);
                toast({ slim: true, position: "top-right", title: 'Failed', description: `Failed to update finance provider. Please contact support.`, status: "error", isClosable: true })
            })
            .finally(() => setActionFinanceOption(null));
    }

    const getFinanceOptions = () => {
        !!enterprise && !!enterprise.id && getRequest(`finance/buyers/${enterprise.id}/finance-options`)
            .then(res => setFinanceOptions(res))
            .catch(() => setDataError(true))
    }

    useEffect(() => {
        getFinanceOptions()
    }, [enterprise])

    return (
        <LoadingScreen
            hasData={!!express}
            hasError={dataError}
            render={() => (
                <Fragment>
                    <ConfirmModal
                        isOpen={!!actionFinanceOption}
                        buttonText={!!actionFinanceOption && !!actionFinanceOption.enabled ? "Disable" : "Enable"}
                        buttonVariant={!!actionFinanceOption && !!actionFinanceOption.enabled ? "destructive" : "primary"}
                        message={`Are you sure you would like to ${!!actionFinanceOption && !!actionFinanceOption.enabled === true ? "disable" : "enable"} ${!!actionFinanceOption && !!actionFinanceOption.name ? actionFinanceOption.name : ''} express payment options for this enterprise?`}
                        onConfirm={() => updateFinanceOptions()}
                        onClose={() => setActionFinanceOption(null)} />
                    <Stack flex="1" row>
                        <Stack flex="1">
                            <Stack row responsive>
                                <HeaderSection title="Details" flex="1">
                                    <Box>
                                        <Stack row responsive>
                                            <Stack flex="1">
                                                <Field label="Id" value={enterprise.id} />
                                                <Field label="Title" value={enterprise.name} />
                                                <Field label="Credit Limit" value={enterprise.creditLimit} />
                                                <A cursor="pointer" onClick={suppliersTabClick}>View Suppliers</A>
                                            </Stack>
                                            <Stack flex="1">
                                                <Field label="App Url" value={enterprise.appUrl} />
                                                <Field label="Api Url" value={enterprise.apiUrl} />
                                                <Field label="Api Key" value={enterprise.apiKey} />
                                                <A cursor="pointer" onClick={workTabClick}>View Work</A>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </HeaderSection>
                            </Stack>
                            <Stack row responsive>
                                <HeaderSection title="Billing Terms" flex="1">
                                    <Box>
                                        <Stack >
                                            <Field label="Invoice Schedule" value={cronstrue.toString(enterprise.billingTerms.invoiceSchedule, { use24HourTimeFormat: true })} />
                                            <Field label="Payment Term Days" value={enterprise.billingTerms.paymentTermDays} />
                                            <Field label="Payment Rate" value={enterprise.billingTerms.paymentRate} />
                                        </Stack>
                                    </Box>
                                </HeaderSection>
                                <HeaderSection title="Instant Billing Terms" flex="1">
                                    <Box>
                                        <Stack >
                                            <Field label="Invoice Schedule" value={cronstrue.toString(enterprise.instantBillingTerms.invoiceSchedule, { use24HourTimeFormat: true })} />
                                            <Field label="Payment Term Days" value={enterprise.instantBillingTerms.paymentTermDays} />
                                            <Field label="Payment Rate" value={enterprise.instantBillingTerms.paymentRate} />
                                        </Stack>
                                    </Box>
                                </HeaderSection>
                            </Stack>
                            <Stack row responsive>
                                <HeaderSection title="Average Payment Statistics" flex="1">
                                    <Box>
                                        <Stack >
                                            <Field label="Average Express Payment" value={<FooterMonthBarChart maxHeight={60} values={express} annotations={["hour", "hours"]} />} />
                                            <Field label="Average Standard Payment" value={<FooterMonthBarChart maxHeight={60} values={standard} annotations={["hour", "hours"]} />} />
                                        </Stack>
                                    </Box>
                                </HeaderSection>
                                <HeaderSection title="Contact Details" flex="1">
                                    <Box>
                                        <Stack>
                                            <Field label="Accounts Contact" value={`${enterprise.accountsContact.name} - ${enterprise.accountsContact.email}`} />
                                            <Field label="Business Contact" value={`${enterprise.businessContact.name} - ${enterprise.businessContact.email}`} />
                                            <Field label="Technical Contact" value={`${enterprise.technicalContact.name} - ${enterprise.technicalContact.email}`} />
                                        </Stack>
                                    </Box>
                                </HeaderSection>
                            </Stack>
                        </Stack>
                        <Stack width={["100%", "300px", "300px"]}>
                            <ButtonStack>
                                {
                                    ((!!financeOptions && !!financeOptions.providers) ? (financeOptions.providers || []) : []).map(p => <Button key={p.id} variant={p.enabled ? "destructive" : "primary"} onClick={() => setActionFinanceOption(p)}>{`${!!p.enabled ? "Disable" : "Enable"} ${p.name} Express Payments`}</Button>)
                                }
                            </ButtonStack>
                        </Stack>
                    </Stack>
                </Fragment>
            )}
        />
    )
}

export default BuyerDetailsTab