import React, { Fragment, useState, useEffect } from 'react'
import {
    P,
    Stack,
    Tooltip,
    IconButton,
    H4,
    Sub,
    ButtonStack,
    Flex,
    StateIndicator,
    Avatar,
    Badge,
    Table,
    HeaderSection,
    Box,
    PaginationControl
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import NoDataScreen from "components/NoDataScreen"
import { useRequest } from 'components/use-request'
import { formatDateTime } from 'helpers/date-helper'
import AgreementInfoDrawer from './components/AgreementInfoDrawer'

const Agreements = ({ organisationId, enterpriseId, onRefresh, ...rest }) => {
    const { postRequest } = useRequest()
    const [selectedInfoAgreement, setSelectedInfoAgreement] = useState(null)
    const [agreements, setAgreements] = useState(null)
    const [totalResults, setTotalResults] = useState(null)
    const [dataError, setDataError] = useState(null)
    const [pageData, setPageData] = useState({
        page: 1,
        pageSize: 10
    })

    const agreementHeadings = [
        { "header": "Name", "accessor": "name" },
        { "header": "Version", "accessor": "version" },
        { "header": "Status", "accessor": "status" },
        { "header": "Sign Off", "accessor": "signers", "overflow": "visible" },
        { "header": "", "accessor": "actions" }
    ]

    const getSearchParams = () => {
        return {
            page: pageData.page,
            pageSize: pageData.pageSize
        }
    }

    useEffect(() => {
        postRequest(`organisations/${organisationId}/${enterpriseId}/search/agreements`, getSearchParams())
            .then(res => {
                setAgreements(res.items)
                setTotalResults(res.totalResults)
            })
            .catch(() => {
                setDataError(true)
            })
    }, [organisationId, pageData])

    const getSignerStatusIcon = (s, index) => {
        let state = 0;
        if (s.signedOn) state = 1;
        else if (s.declinedOn) state = 2

        return (
            <StateIndicator key={index} state={state} size="1.75rem">
                <Avatar name={s.name} variant={s.isSupplier ? "org" : "user"} />
            </StateIndicator>
        )
    }

    const getSignerStatus = (s) => {
        if (s.signedOn) return `Signed Off`
        else if (s.declinedOn) return `Declined`
        else return "Awaiting"
    }

    const getSignerStatusDate = (s) => {
        if (s.signedOn) return <Sub lightText>{formatDateTime(s.signedOn)}</Sub>
        else if (s.declinedOn) return <Sub lightText>{formatDateTime(s.declinedOn)}</Sub>
        else return null
    }

    const agreementTransform = (data) => {
        return {
            id: data.id,
            props: {
                3: { overflow: "inherit" }
            },
            status: (
                <Fragment>
                    {data.status === 0 && <Badge status="info">Unsigned</Badge>}
                    {data.status === 1 && <Badge status="success">Signed</Badge>}
                    {data.status === 2 && <Badge status="error">Declined</Badge>}
                </Fragment>
            ),
            version: data.version,
            name: data.name,
            signers: (
                <Tooltip
                    direction="bottom"
                    p={16}
                    content={() =>
                        <Stack spacing={16} minWidth={[undefined, "200px", "200px"]} maxWidth={[undefined, "250px", "250px"]}>
                            {data.signers.map((s, index) =>
                                <Flex alignItems="center" key={index}>
                                    {getSignerStatusIcon(s, index)}
                                    <Flex flexDirection="column" ml={16}>
                                        <H4 overflow="hidden" textOverflow="ellipsis" maxWidth="9.25rem">{s.name}</H4>
                                        <Sub lightText>{getSignerStatus(s, data)}</Sub>
                                        {getSignerStatusDate(s)}
                                    </Flex>
                                </Flex>
                            )}
                        </Stack>
                    }>
                    <Stack row spacing={8}>
                        {data.signers.map(getSignerStatusIcon)}
                    </Stack>
                </Tooltip>
            ),
            actions: (
                <Flex width="100%">
                    <ButtonStack compact ml="auto" row>
                        <IconButton title="View Contract Info" isSmall onClick={() => { setSelectedInfoAgreement(data) }} icon="info" />
                        {!!data.pdfLink && <IconButton title="Open Contract" as="a" isSmall target="_blank" href={data.pdfLink} icon={"external-link"} />}
                    </ButtonStack>
                </Flex>
            )
        }
    }

    return (
        <HeaderSection
            title="Agreements"
            {...rest}>
            <LoadingScreen
                hasData={agreements}
                hasError={dataError}
                render={() =>
                    <Fragment>
                        {
                            agreements.length === 0
                                ? <Box><P>No Agreements Found</P></Box>
                                : <Fragment>
                                    <Table columns={agreementHeadings} data={agreements} transform={agreementTransform} noData={<NoDataScreen />} />
                                    <PaginationControl pageData={pageData} updatePageData={setPageData} dataCount={!!agreements ? agreements.length : 0} totalDataCount={!!totalResults ? totalResults : null} hideIfOnlyOnePage={true} mt={20} />
                                </Fragment>
                        }
                        {!!selectedInfoAgreement && <AgreementInfoDrawer selectedAgreement={selectedInfoAgreement} onClose={() => setSelectedInfoAgreement(undefined)} isOpen={!!selectedInfoAgreement}></AgreementInfoDrawer>}
                    </Fragment>
                } />
        </HeaderSection>
    )
}

export default Agreements