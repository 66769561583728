import React, { useState, useEffect, Fragment } from 'react'
import {
    Box,
    Button,
    Stack,
    Flex,
    H3,
    H4,
    PseudoElement,
    Img,
    PageContent,
    ButtonStack,
    FilterButton,
    Badge,
    Link,
    Label,
    Icon,
    Avatar,
} from 'paid-ui-lib'
import LoadingScreen from 'components/LoadingScreen'
import { useRequest } from 'components/use-request'
import { convertStatusState } from 'helpers/status-helper'
import {
    initFilteredDataTable,
    FilteredDataTable
} from 'helpers/filter-helper'
import { formatDateTime } from 'helpers/date-helper'

const WorkTab = ({ organisation, location }) => {
    const [selectedEnterprise, setSelectedEnterprise] = useState(null)
    const [enterprises, setEnterprises] = useState([])

    const { getRequest, postRequest } = useRequest()
    const [dataError, setDataError] = useState(false)
    const [pageData, setPageData] = useState()
    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState(false)
    const [activeFilters, setActiveFilters] = useState()
    const [workDownload, setWorkDownload] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [tableLoaded, setTableLoaded] = useState(false)

    const transformFilters = (filters) => {
        return filters
    }

    const transformQuickFilters = (quickFilters) => {
        return quickFilters
    }

    useEffect(() => {
        if (!!selectedEnterprise) {
            initFilteredDataTable({
                filterPath: 'work/filters',
                dataPath: `work/${selectedEnterprise.id}/${selectedEnterprise.supplierId}/search`,
                location: location,
                setFilterData: setFilterData,
                setDataError: setDataError
            }).then(showFilter => {
                setShowFilter(showFilter)
                setTableLoaded(true)
            })
        }
    }, [selectedEnterprise])

    const columns = [
        { "header": "Reference", "accessor": "reference", "sortId": "1" },
        { "header": "Title", "accessor": "title", "sortId": "3", maxWidth: "20rem" },
        { "header": "Owner", "accessor": "owner", "sortId": "5" },
        { "header": "Amount", "accessor": "amount", "sortId": "9" },
        { "header": "Updated", "accessor": "updatedOn", "sortId": "6" },
        { "header": "Status", "accessor": "status", "sortId": "7" },
    ]

    const transform = (data) => {
        return {
            id: data.id,
            reference: <Link href={`work/${data.enterprise.id}/${data.id}`} openInNewTab={true}>{data.reference}</Link>,
            title: data.title,
            owner: <Avatar name={data.procurementOwner.name} size={28} />,
            updatedOn: formatDateTime(data.updatedOn),
            status: <Badge status={convertStatusState(data.status.state)}>{data.status.title}</Badge>,
            amount: `${data.currencySymbol || "£"}${data.amount.toFixed(2)}`,
        }
    }

    const getFilterParams = () => {
        return {
            filters: activeFilters.map(af => af.filter),
            page: pageData.page,
            pageSize: pageData.pageSize
        }
    }

    const downloadFile = (path, fileName) => {
        const filters = getFilterParams()
        return postRequest(`${path}`, filters)
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob)
                var a = document.createElement('a')
                a.href = url
                a.download = fileName
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
    }

    const downloadWorkCsv = () => {
        setWorkDownload(true)
        downloadFile(`work/export/${selectedEnterprise.id}`, `${selectedEnterprise.name} - Work-${new Date()}.csv`)
            .finally(() => setWorkDownload(false))
    }

    const groupBy = (n, data) => {
        let result = [];
        for (let i = 0; i < data.length; i += n)
            result.push(data.slice(i, i + n))

        return result
    };

    const backPressed = () => {
        setSelectedEnterprise(null)
        setShowFilter(false)
        location.state.selectedOrg = null
    }

    useEffect(() => {
        if (!!organisation && !!organisation.clients) {
            getRequest("enterprise", { active: true })
                .then((res) => {
                    let results = [];
                    organisation.clients.forEach(c => {
                        const client = res.find(e => e.id === c.enterpriseId);
                        if (!!client) {
                            results.push({ ...client, supplierId: c.organisationId });
                        }
                    })
                    setEnterprises(results);
                })
                .catch(() => setDataError(true))
        }
    }, [organisation])

    return (
        <LoadingScreen
            hasData={!!selectedEnterprise ? tableLoaded : enterprises.length > 0}
            hasError={dataError}
            render={() => (
                <Fragment>
                    {
                        !!selectedEnterprise &&
                        <Stack spacing={8} row alignItems="center">
                            <Label mt="auto" mb="auto" color="blue.600" onClick={() => backPressed()}><Icon name="arrow-left"></Icon> All clients /</Label>
                            <H3 mt="auto" mb="auto" mr="auto">{selectedEnterprise.name}</H3>
                            <ButtonStack row>
                                <FilterButton showFilter={showFilter} onClick={() => setShowFilter(!showFilter)} activeFilters={activeFilters} />
                                <Button onClick={() => downloadWorkCsv()} variant="secondary" aria-label="Download" isLoading={workDownload} showChevron>{workDownload ? "Downloading..." : "Download"}</Button>
                            </ButtonStack>
                            <Box mt="auto" mb="auto" padding={8} >
                                <Img src={selectedEnterprise.logo} alt="Logo" height="2rem" />
                            </Box>
                        </Stack>
                    }
                    {
                        !!selectedEnterprise ? <FilteredDataTable
                            initData={filterData}
                            showFilter={showFilter}
                            setShowFilter={setShowFilter}
                            setDataError={setDataError}
                            transform={transform}
                            columns={columns}
                            transformFilters={transformFilters}
                            transformQuickFilters={transformQuickFilters}
                            setParentActiveFilters={setActiveFilters}
                            setParentPageData={setPageData}
                            sortable={true}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                            : <Stack>
                                {
                                    groupBy(3, enterprises).map((row, i) =>
                                        <Stack key={`row-${i}`} row>
                                            {
                                                row.map((enterprise, i) => (<Stack flex="1" maxWidth="33%" key={`item-${i}`} responsive>
                                                    <Box>
                                                        <Flex>
                                                            <Flex alignItems="center" flexDirection="row" flexWrap="wrap">
                                                                <H4 accessibilityHeading="h3">{enterprise.name}</H4>
                                                            </Flex>
                                                            <PseudoElement ml="auto">
                                                                <Box padding={8}>
                                                                    <Img src={enterprise.logo} alt="Logo" height="2rem" />
                                                                </Box>
                                                            </PseudoElement>
                                                        </Flex>
                                                        <Button
                                                            isLoading={!!selectedEnterprise && selectedEnterprise.id === enterprise.id}
                                                            disabled={!!selectedEnterprise}
                                                            mt={12}
                                                            variant="secondary"
                                                            onClick={() => setSelectedEnterprise(enterprise)}>View Work</Button>
                                                    </Box>
                                                </Stack>))
                                            }
                                        </Stack>)
                                }
                            </Stack>
                    }
                </Fragment>
            )}
        />
    )
}

export default WorkTab