import React, { useState, useEffect } from 'react'
import {
    Stack,
} from 'paid-ui-lib'
import ToggleBox from 'components/ToggleBox'
import ToggleRow from 'components/ToggleRow'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import ChargesPanel from './components/ChargesPanel'
import CompliancePanel from './components/CompliancePanel'
import BankVerificationPanel from './components/BankVerificationsPanel'
import { convertVerificationStatus, getBadge } from 'helpers/status-helper'
    
const OnboardingTab = ({ organisation }) => {
    const { getRequest } = useRequest()
    const [dataError, setDataError] = useState(false)
    const [onboardingDetails, setOnboardingDetails] = useState(null)

    useEffect(() => {
        getRequest(`organisations/${organisation.id}/onboarding`).then(setOnboardingDetails)
    }, [organisation])

    return (
        <LoadingScreen
            hasData={!!onboardingDetails}
            hasError={dataError}
            render={() => (
                <Stack>
                    <ToggleBox key={1} title="Individual Checks">
                        <Stack>
                            <ToggleRow key="phone" label="Phone" {...(!!onboardingDetails && !!onboardingDetails.telephone ? { badgeText: convertVerificationStatus(onboardingDetails.telephone.status), badgeStatus: getBadge(convertVerificationStatus(onboardingDetails.telephone.status)) } : {})}></ToggleRow>
                            <ToggleRow key="email" label="Email" {...(!!onboardingDetails && !!onboardingDetails.email ? { badgeText: convertVerificationStatus(onboardingDetails.email.status), badgeStatus: getBadge(convertVerificationStatus(onboardingDetails.email.status)) } : {})}></ToggleRow>
                        </Stack>
                    </ToggleBox>

                    <CompliancePanel organisation={organisation} organisationId={organisation.id} verificationId={!!onboardingDetails && !!onboardingDetails.compliance && !!onboardingDetails.compliance.verificationId ? onboardingDetails.compliance.verificationId : null} />

                    <ToggleBox key={3} title="Bank Verifications" {...(!!onboardingDetails && !!onboardingDetails.bank ? { badgeText: convertVerificationStatus(onboardingDetails.bank.status), badgeStatus: getBadge(convertVerificationStatus(onboardingDetails.bank.status)) } : {})}>
                        <BankVerificationPanel organisationId={organisation.id}></BankVerificationPanel>
                    </ToggleBox>

                    <ToggleBox key={4} title="Charges" {...(!!onboardingDetails && !!onboardingDetails.charges ? { badgeText: convertVerificationStatus(onboardingDetails.charges.status), badgeStatus: getBadge(convertVerificationStatus(onboardingDetails.charges.status)) } : {})}>
                        <ChargesPanel organisationId={organisation.id}></ChargesPanel>
                    </ToggleBox>
                </Stack >
            )}
        />)
}

export default OnboardingTab