import React, { useState } from 'react'
import ConfigManagerContext from "../ConfigManagerContext"
import {
    UIConfigProvider,
} from 'paid-ui-lib'

export const ConfigManager = ({ children, ...rest }) => {
    const [context ] = useState({ initialised: true });

    return (
        <ConfigManagerContext.Provider value={context} {...rest}>
            <UIConfigProvider config={{config: { apiUrl: window._paidApiHost}}}>
                {children}
            </UIConfigProvider>
        </ConfigManagerContext.Provider>
    )
}

export default ConfigManager