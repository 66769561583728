import React from 'react'
import {
    IconCircle,
    Box,
    H2,
    H4,
    Flex,
    Icon,
    checkKeyIsValid,
    useTheme,
} from 'paid-ui-lib'

const DashboardStat = ({ title, value, icon, primaryStat, onClick, ...rest }) => {
    const theme = useTheme()

    return (
        <Box
            tabIndex="0"
            role="link"
            flex="1"
            py={20}
            px={24}
            border={primaryStat ? "none" : undefined}
            backgroundColor={primaryStat ? "blue.600" : "white.100"}
            color={primaryStat ? "white.100" : undefined}
            cursor={!!onClick ? "pointer" : undefined}
            onClick={onClick}
            _hover={{ backgroundColor: primaryStat ? theme.colors.blue[700] : theme.colors.grey[200]}}
            onKeyDown={(e) => checkKeyIsValid(e) && !!onClick && onClick(e)}
            {...rest}>
            <IconCircle name={icon} mb={12} size={32} color={primaryStat ? "white.100" : "grey.500"} backgroundColor={primaryStat ? "blue.400" : undefined} />
            <H4 accessibilityHeading="h2" mb={8}>{title}</H4>
            <Flex alignItems="center" >
                <H2 accessibilityHeading="h3">{value}</H2>
                {!!onClick && <Icon ml="auto" name="chevron-right" color="blue.100" size={20} />}
            </Flex>
        </Box>
    )
}

export default DashboardStat