import React, { useState, useEffect, Fragment } from 'react'
import { useRequest } from 'components/use-request'
import LoadingScreen from 'components/LoadingScreen'
import {
    Badge,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    Field,
    Button,
    DrawerFooter,
    HeaderSection,
    Flex,
    H4,
    HR,
    Table,
    formatDate,
    formatDateTime,
    IconButton,
    Span,
    Stack,
} from 'paid-ui-lib'
import AuthenticatorTfa from 'components/AuthenticatorTfa'
import NoDataScreen from "components/NoDataScreen"
import { ButtonStack } from 'paid-ui-lib/dist/lib/core-components/Layout/ButtonStack'

const PaymentActions = {
    Authorise: 1,
    Process: 2,
    Reject: 3,
    ReleaseHold: 4,
    GenerateCard: 5,
    CloseCard: 6
}

const PaymentDetailsDrawer = ({ paymentId, loading, onClose, onAuthorise, onProcess, onReleaseHold, onGenerateCard, onCloseCard }) => {
    const [action, setAction] = useState(null)
    const [invoices, setInvoices] = useState(undefined)
    const [statements, setStatements] = useState(undefined)
    const [payment, setPayment] = useState(undefined)
    const [virtualCardStatus, setVirtualCardStatus] = useState(undefined)
    const [paymentHistory, setPaymentHistory] = useState(null)
    const { getRequest } = useRequest()

    useEffect(() => {
        if (!!paymentId) {
            getRequest(`payments/${paymentId}`).then(setPayment);
            getRequest(`payments/${paymentId}/history`).then(setPaymentHistory);
        }
    }, [])

    useEffect(() => {
        if (!!payment) {
            getRequest(`payments/invoices/${payment.buyerOrganisationId}/${payment.productId}`).then(setInvoices).catch(() => setInvoices([]));
            getRequest(`payments/statements/${payment.buyerOrganisationId}/${payment.productId}`).then(setStatements).catch(() => setStatements([]));
            !!payment.virtualCardPayment && getRequest(`payments/${payment.id}/virtual-cards/${payment.bankAccountId}/status`).then(setVirtualCardStatus);
        }
    }, [payment])

    const getStatus = () => {
        if (!!payment.virtualCardPayment) {
            if (!!virtualCardStatus) {
                if (!!virtualCardStatus.closedOn) {
                    return "Closed";
                }
                else if (!!virtualCardStatus.generatedOn) {
                    return "Active";
                }
                else {
                    return "Awaiting Card";
                }
            }

            return "Failed";
        }

        switch (payment.status) {
            case 1:
                return "Awaiting Authorisation"
            case 2:
                return "Awaiting Payment"
            case 3:
                return "Paid"
            case 4:
                return "Rejected"
        }
    }

    const supplierInvoiceHeadings = [
        { "header": "Reference", "accessor": "reference" },
        { "header": "Amount", "accessor": "amount" },
        { "header": "Created On", "accessor": "createdOn" },
        { "header": "", "accessor": "pdfLink" }
    ]

    const paymentHistoryHeadings = [
        { "header": "Message", "accessor": "message", "fontWeight": "normal" },
        { "header": "Created On", "accessor": "createdOn" }
    ]

    const TransformInvoice = (data) => {
        return {
            id: data.id,
            pdfLink:
                (
                    <Flex width="100%">
                        {!!data.pdfLink && <IconButton title="Open" as="a" variant="secondary" isSmall target="_blank" href={`${data.pdfLink || data.url}&open=true`} icon="external-link" />}
                    </Flex>
                ),
            reference: data.invoiceReference,
            amount: `${data.amount.toFixed(2)}`,
            createdOn: formatDate(data.createdOn)
        }
    }

    const TransformStatement = (data) => {
        return {
            id: data.id,
            pdfLink:
                (
                    <Flex width="100%">
                        {!!data.pdfLink && <IconButton title="Open" as="a" variant="secondary" isSmall target="_blank" href={`${data.pdfLink || data.url}&open=true`} icon="external-link" />}
                    </Flex>
                ),
            reference: data.reference,
            amount: `${data.amount.toFixed(2)}`,
            createdOn: formatDate(data.createdOn)
        }
    }

    const transformPaymentHistory = (data) => ({
        message: <Span whiteSpace="break-spaces">{data.message}</Span>,
        createdOn: <Span>{formatDate(data.createdOn)}</Span>
    })

    const actionPayment = (action, code) => {
        switch (action) {
            case PaymentActions.ReleaseHold:
                !!onReleaseHold && onReleaseHold(code, payment);
                break;
            case PaymentActions.GenerateCard:
                !!onGenerateCard && onGenerateCard(code, payment);
                break;
            case PaymentActions.Authorise:
                !!onAuthorise && onAuthorise(code, payment);
                break;
            case PaymentActions.Process:
                !!onProcess && onProcess(code, payment);
                break;
            case PaymentActions.CloseCard:
                !!onCloseCard && onCloseCard(code, payment);
                break;
        }
        setAction(null);
    }

    return (
        <Drawer
            size="md"
            isOpen={!!paymentId}
            placement="right"
            onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent rounded="0">
                <DrawerHeader>Payment Details</DrawerHeader>
                <DrawerBody overflowY="scroll">
                    <Stack>
                        <LoadingScreen
                            hasData={!!payment && !!payment.virtualCardPayment ? !!virtualCardStatus : !!payment}
                            hasError={false}
                            render={() => (
                                <Stack mb={4}>
                                    <Stack row flex="1">
                                        <Field label="Id" value={payment.id} />
                                        {
                                            !!payment && !!payment.holdOn && <Flex><Badge my="auto" status="error">Held</Badge></Flex>
                                        }
                                    </Stack>
                                    <Stack row flex="3">
                                        <Field flex="1" label="Reference" value={payment.reference} />
                                        <Field flex="1" label="Status" value={getStatus()} />
                                        <Field flex="1" label="Amount" value={`${payment.currencySymbol}${parseFloat(payment.amount).toFixed(2)}`} />
                                    </Stack>
                                    <HR />
                                    {
                                        !payment.virtualCardPayment
                                            ? <Stack row flex="3">
                                                <Field flex="1" label="Account Name" value={payment.accountName} />
                                                <Field flex="1" label="Account Number" value={payment.accountNumber} />
                                                <Field flex="1" label="Sort Code" value={payment.sortCode} />
                                            </Stack>
                                            : <Stack>
                                                <Field flex="1" label="Payment Type" value={!!payment.virtualCardPayment ? "Virtual Card" : "Bank Account"} />
                                                {
                                                    !!virtualCardStatus && <Stack row>
                                                        <Field flex="1" label="Created On" value={!!virtualCardStatus.createdOn ? formatDateTime(virtualCardStatus.createdOn) : "-"} />
                                                        <Field flex="1" label="Generated On" value={!!virtualCardStatus.generatedOn ? formatDateTime(virtualCardStatus.generatedOn) : "-"} />
                                                        <Field flex="1" label="Closed On" value={!!virtualCardStatus.closedOn ? formatDateTime(virtualCardStatus.closedOn) : "-"} />
                                                    </Stack>
                                                }
                                            </Stack>
                                    }
                                </Stack>
                            )} />
                        <HR />
                        <Stack>
                            <H4>Invoices</H4>
                            <LoadingScreen
                                hasData={!!invoices}
                                hasError={false}
                                render={() => <Table columns={supplierInvoiceHeadings} data={invoices} transform={TransformInvoice} noData={<NoDataScreen />} />} />
                        </Stack>
                        <Stack>
                            <H4>Statements</H4>
                            <LoadingScreen
                                hasData={!!statements}
                                hasError={false}
                                render={() => <Table columns={supplierInvoiceHeadings} data={statements} transform={TransformStatement} noData={<NoDataScreen />} />} />
                        </Stack>
                        <Stack>
                            <H4>History</H4>
                            <LoadingScreen
                                hasData={paymentHistory !== null}
                                hasError={false}
                                render={() => <Table columns={paymentHistoryHeadings} data={paymentHistory} transform={transformPaymentHistory} noData={<Span>No payment history</Span>} />} />
                        </Stack>
                    </Stack>
                </DrawerBody>
                <DrawerFooter>
                    <Stack mt={12} flex="1">
                        {
                            !!payment &&
                            <Stack>
                                {
                                    !!action
                                        ? <AuthenticatorTfa onTfaSubmit={c => actionPayment(action, c)} />
                                        : payment.status !== 4 &&
                                        <Fragment>
                                            {
                                                !!payment.virtualCardPayment && !!virtualCardStatus && !!virtualCardStatus.createdOn
                                                    ? <ButtonStack flex="1" responsive>
                                                        {!virtualCardStatus.generatedOn && <Span>This is a virtual card payment. Generating a virtual card using an external card provider will automatically authorise and process the payment.</Span>}
                                                        {!virtualCardStatus.generatedOn && <Button flex="1" disabled={loading} isLoading={loading} onClick={() => setAction(PaymentActions.GenerateCard)}>Generate Card</Button>}
                                                        {!!virtualCardStatus.generatedOn && !virtualCardStatus.closedOn && <Button flex="1" disabled={loading} isLoading={loading} onClick={() => setAction(PaymentActions.CloseCard)}>Close Card</Button>}
                                                    </ButtonStack>
                                                    : <ButtonStack flex="1" responsive>
                                                        {
                                                            !!payment.holdOn
                                                                ? <Button flex="1" disabled={loading} isLoading={loading} onClick={() => setAction(PaymentActions.ReleaseHold)}>Remove Hold</Button>
                                                                : <Fragment>
                                                                    {payment.status === 1 && <Button flex="1" disabled={loading} isLoading={loading} onClick={() => setAction(PaymentActions.Authorise)}>Authorise Payment</Button>}
                                                                    {payment.status === 2 && (!payment.expressPayment || payment.buyerOrganisationId === "049fd28f-940d-41bd-8806-7cb87f353345") && <Button flex="1" disabled={loading} isLoading={loading} onClick={() => setAction(PaymentActions.Process)}>Process Payment</Button>}
                                                                </Fragment>
                                                        }
                                                    </ButtonStack>
                                            }
                                        </Fragment>
                                }
                            </Stack>
                        }
                    </Stack>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default PaymentDetailsDrawer